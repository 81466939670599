.social-grid {
	display: flex;
	flex-direction: row;
	gap: calc(var(--spacing));
	justify-content: center;

	> a {
		z-index: 1;
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: calc(var(--spacing) * 1.5);
		color: var(--color-text);
		padding: calc(var(--spacing) / 2) !important;

		i {
			margin: 0 !important;
			min-width: 40px;
			width: 40px;
			height: 40px;
			display: grid;
			place-items: center;
		}

		span.subtitle {
			font-size: 0.8rem;
			font-weight: 300;
			opacity: 0.8;
		}
	}
}
