.header-actions {
	position: fixed;
	top: var(--spacing);
	left: 50%;
	transform: translateX(-50%);
	z-index: 10000;

	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: var(--spacing);

	select,
	button {
		color: var(--color-text);
		background-color: var(--container-background);
		border-radius: var(--border-radius);
		padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);
		box-shadow: none;
		text-shadow: none;
		border: none;
		filter: none;
	}
}

section.header {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: var(--spacing);

	> * {
		flex: 1;
	}

	> .image-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
	}

	.gradient-text {
		background: var(--gradient-accent);
		background-clip: text;
		color: transparent;

		font-weight: 900;
		font-size: 3em;
		line-height: 1.2;
		letter-spacing: 0.03em;
		margin-block: 0;

		text-shadow: -3px -3px rgba(255, 255, 255, 0.5);

		margin-bottom: calc(var(--spacing) * 2);
	}

	.typed-text {

		&:after {
			content: "|";
			animation: blink 0.7s infinite;
		}

		@keyframes blink {
			0% {
				opacity: 0;
			}
			50% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}
	}

	@media screen and (max-width: 700px) {
		flex-direction: column;
	}
}
