.skills {
	position: relative;

	.skills-container-lower {
		align-items: center;
		display: flex;
		flex-direction: row !important;
		justify-content: center;
		gap: calc(var(--spacing) * 2);
		margin-bottom: calc(var(--spacing) * 2);

		i {
			height: 60px;
			width: 60px;
			display: grid;
			place-items: center;
			background-color: var(--color-accent);
			border-radius: 50%;
		}
	}

	.certifications {
		display: flex;
		gap: calc(var(--spacing) * 2);
		justify-content: center;
		margin-top: calc(var(--spacing) * 4);

		.certification {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: var(--spacing);

			img {
				--certification-badge-size: 128px;

				min-height: var(--certification-badge-size);
				height: var(--certification-badge-size);
				max-height: var(--certification-badge-size);
				min-width: var(--certification-badge-size);
				width: var(--certification-badge-size);
				max-width: var(--certification-badge-size);
				object-fit: contain;

				object-fit: contain;
				padding: var(--spacing);
				background: white;
				border-radius: var(--border-radius);
			}
		}
	}

	@media screen and (max-width: 700px) {
		.skills-container-lower {
			display: grid !important;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, 1fr);
			justify-items: center;

			&:first-child {
				margin-bottom: calc(var(--spacing) * 2);
			}
		}
	}
}